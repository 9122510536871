import React, { createRef } from 'react'
import './dr-datatable.component.scss'
import 'datatables.net-rowgroup'
import 'datatables.net-rowreorder'
import $ from 'jquery';
import { dataTableLang } from '../../hoc/Constants/dr-constants.component';
import { render as RenderHtml } from '@react-email/render';

//const $ = require('jquery')
// $.DataTable = require("datatables.net")
// require('datatables.net-buttons')(window, $);
const dtable = createRef();

// const Control = ({ children, ...props }) => (
//     <components.Control {...props}>
//         Page length → {children}
//     </components.Control>
// );

// const customStyles = {
//     singleValue: (base) => ({
//         ...base,
//         padding: "5px 10px",
//         borderRadius: 5,
//         //background: selected,
//         color: "black",
//         display: "flex",
//         width: "80px",
//     }),
// };

export function format(columns, childData) {

    return RenderHtml(
        <div className="sub-table">
            <table className="table text-nowrap table-bordered dataTable">
                <thead>
                    <tr >
                        {columns?.map((col, index) => {

                            return (
                                <th className={`text-uppercase ${col.checkall ? "text-start" : ""}`}
                                    style={
                                        {
                                            minWidth: (col.minWidth !== null ? col.minWidth : 'auto'),
                                            maxWidth: (col.maxWidth !== null ? col.maxWidth : 'auto'),
                                            verticalAlign: 'middle',
                                            fontWeight: (col.isBold ? 'bold' : ''),
                                            position: 'relative'
                                        }
                                    }
                                    key={index}>
                                    {col.title}
                                </th>
                            )
                        }
                        )}
                    </tr>
                </thead>
                <tbody>
                    {childData?.map((rowData, index) => {

                        return (
                            <tr className={"text-start"}
                                style={
                                    {
                                        minWidth: 'auto',
                                        maxWidth: 'auto',
                                        verticalAlign: 'middle',
                                        fontWeight: 'bold',
                                        position: 'relative'
                                    }
                                }
                                key={"row_" + index}>

                                {columns?.map((col, index) => {

                                    return (
                                        <td
                                            style={
                                                {
                                                    minWidth: (col.minWidth !== null ? col.minWidth : 'auto'),
                                                    maxWidth: (col.maxWidth !== null ? col.maxWidth : 'auto'),
                                                    verticalAlign: 'middle',
                                                    fontWeight: (col.isBold ? 'bold' : ''),
                                                    position: 'relative'
                                                }
                                            }
                                            key={"col_" + index}>
                                            {col.checkall && col.checkData && <input type="checkbox" className="ckboxsub ms-3" name="name" value={rowData[col.checkData]} />}
                                            {!(col.checkall && col.checkData) && col.data ? rowData[col.data] : ""}
                                        </td>
                                    )
                                }
                                )}
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
        </div>

    )




}

class DrDataTable extends React.Component {
    componentDidMount() {
        this.createDataTable(this.props);
        this.onNextFrame()
    }

    componentDidUpdate(prevProps) {
        if ((JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) || JSON.stringify(prevProps.archivedData) !== JSON.stringify(this.props.archivedData))) {
            this.createDataTable(this.props);
        }
        this.onNextFrame()
    }

    componentWillUnmount() {
        const $dtable = $(dtable.current);
        $dtable.DataTable().clear();
        $dtable.DataTable().destroy();
        $("#dtable_wrapper").empty()
        $("#dtable").empty()
    }

    onNextFrame = () => {
        setTimeout(function () {
            requestAnimationFrame(function () { $('#dtable').DataTable().columns.adjust().draw() })
        })
    }

    buildSelectboxSearch = (table, columns) => {
        $("#dtable_filter label").hide();

        table.columns().every(function () {
            var column = table.column(this, { search: 'applied' });

            var listIndex = column[0].toString();
            var columnTitle = columns[listIndex].title;
            let data = column.data().filter(function (value) { return typeof value !== "string" ? false : true }).unique().sort().toArray();

            $('.dataTables_scrollBody').find('.clear-input').remove()

            if (data.length > 0) {
                // On every keypress in this input
                var select = $(`<input autocomplete="off" name="input-${listIndex}" class="form-control form-control-sm" placeholder="${columnTitle}" list="${listIndex}"></input><datalist id="${listIndex}"></datalist>`)
                    .appendTo($(column.header()).empty())
                    .on('change', function () {
                        var val = $(this).val()
                        var escapedVal = $.fn.dataTable.util.escapeRegex(
                            $(this).val()
                        );
                        // Get the search value
                        column
                            .search(escapedVal ? '(' + escapedVal + ')' : '', true, false)
                            .draw();

                        /// Add Clear icon for selected text
                        if (val) {
                            $(`<span class='clear-input' id="clear-input-${listIndex}">x</span>`).appendTo($(column.header())).on('click', function () {
                                $(`input[name="input-${listIndex}"]`).val('')
                                table
                                    .column(listIndex)
                                    .search('')
                                    .draw();
                                $(`#clear-input-${listIndex}`).remove()
                            })
                        }

                        $(`input[name="input-${listIndex}"]`).val(val)
                    });
                data.forEach(d => {
                    select.append('<option value="' + d + '">' + d + '</option>')
                })

                //// For cascade search
                var currSearch = column.search();
                if (currSearch) {
                    /// Add Clear icon for selected text
                    $(`<span class='clear-input' id="clear-input-${listIndex}">x</span>`).appendTo($(column.header())).on('click', function () {
                        $(`input[name="input-${listIndex}"]`).val('')
                        table
                            .column(listIndex)
                            .search('')
                            .draw();
                        $(`#clear-input-${listIndex}`).remove()
                    })
                    var unescapedVal = (currSearch.substring(1, currSearch.length - 1)).replace(/\\(.)/g, function ($0, $1) {
                        return $1;
                    })
                    select.val(unescapedVal);
                }
            }

            return null;
        })
    }

    buildTextboxSearch = (table, columnsArray) => {
        $("#dtable_filter label").hide()

        // For each column
        table
            .columns()
            .eq(0)
            .each(function (colIdx) {
                // Set the header cell to contain the input element
                var cell = $('.filters th').eq(
                    $(table.column(colIdx).header()).index()
                );
                var title = $(cell).text();
                // if (title === "") return;

                var coll = columnsArray[colIdx];
                if (columnsArray.find(x => x.searchable)) {
                    $(cell).addClass("searchable-column");
                }
                if (coll) {
                    if (coll.searchable && !coll.checkall) {
                        // $(cell).addClass("searchable-column");
                        $(cell).html('<input type="text" class="form-control form-control-sm" placeholder="' + title + '" />');
                    }
                    else if (coll.checkall) {
                        $(cell).html('<input type="checkbox" class="form-check-input form-checked-success ckbox" style="width:16px" name="name" value=""  />');
                    }
                }


                // On every keypress in this input
                $('input', $('.filters th').eq($(table.column(colIdx).header()).index()))
                    .on('keyup change clear', function (e) {
                        e.stopPropagation();
                        var coll = columnsArray[colIdx];
                        if (coll.checkall) {

                            $('input.ckbox[type=checkbox]', $('#dtable tbody tr')).prop('checked', this.checked);
                            if (this.checked) {
                                $('input.ckbox[type=checkbox]', $('#dtable tbody tr')).closest("tr").addClass("selected");
                            }
                            else {
                                $('input.ckbox[type=checkbox]', $('#dtable tbody tr')).closest("tr").removeClass("selected");
                            }
                        }
                        else {

                            // Get the search value
                            $(this).attr('title', $(this).val());

                            var regexr = '({search})'; //$(this).parents('th').find('select').val();
                            // Search the column for that value

                            if (coll.searchGroupName?.length > 0) {

                                table
                                    .columns(("." + coll.searchGroupName))
                                    .search(
                                        this.value !== '' ? regexr.replace('{search}', '(((' + this.value + ')))') : '',
                                        this.value !== '',
                                        this.value === ''
                                    )
                                    .draw();
                            }
                            else {
                                table
                                    .column(colIdx)
                                    .search(
                                        this.value !== '' ? regexr.replace('{search}', '(((' + this.value + ')))') : '',
                                        this.value !== '',
                                        this.value === ''
                                    )
                                    .draw();
                            }

                        }
                    });

                $('input', $('.filters th').eq($(table.column(colIdx).header()).index()))
                    .on('click', function (e) {
                        e.stopPropagation();
                    });

            });
    }




    createDataTable = (props) => {
        const $dtable = $(dtable.current);
        const _this = this;

        let options = {
            bDestroy: true,
            ordering: props.isDragDropEnabled ? false : true,
            order: props.order || [[0, 'desc']],
            data: props.data,
            columns: props.columns,
            paging: !props.isDragDropEnabled,
            lengthChange: true,
            // lengthMenu: [5, 10, 20, 40, 60, 80, 100, 200, 500, 1000, 2000],
            lengthMenu: [[5, 10, 20, 40, 60, 80, 100, 200, 500, 1000, 2000, -1], [5, 10, 20, 40, 60, 80, 100, 200, 500, 1000, 2000, "All"]],
            pageLength: props.pageLength || 5,
            searching: true,
            language: dataTableLang,
            columnDefs: props.columnDefs,
            //dom: 'Blfrtip',
            // dom: props.dom || '<"top">rt<"bottom"ipl><"clear">',
            //dom: props.dom || '<"top">rt<"bottom" <"row px-3" <"col-auto"i>> <"row px-3 justify-content-between" <"col-12"p><"col-12"l>> ><"clear">',
            // dom: props.dom || '<"top">rt<"bottom" <"row px-3" <"col-auto m-1"i>> <"row px-3 justify-content-center" <"col-auto m-1"p>> <"row px-3  justify-content-center" <"col-auto m-1"l>> ><"clear">',
            dom: props.dom || '<"row border br-t-5" <"col-12 p-0" rt>> <"row" <"col-sm-12 col-md-6" i> <"col-sm-12 col-md-6" >> <"row mb-3" <"col-sm-12 col-md-5" l> <"col-sm-12 col-md-7" p>> ',
            scrollX: true,
            // processing: props.IsServerSide,
            serverSide: props.isServerSide,
            retrieve: props.isServerSide,
            responsive: props.isServerSide,
            processing: props.isServerSide,

            deferLoading: 0,
            ajax: props.isServerSide ? props.ajax : null,
            // pagination: props.IsServerSide,
            // paginationServer: props.IsServerSide,
            // paginationTotalRows: props.IsServerSide ? props.paginationTotalRows : 0,
            // paginationDefaultPage: props.IsServerSide ? props.paginationDefaultPage : 1,
            // onChangeRowsPerPage: props.IsServerSide ? props.handlePerRowsChange : null,
            // onChangePage: props.IsServerSide ? props.handlePageChange : null


            // retrieve: true,
            // destroy: true,
            // stateSave: true,
            // stateSaveCallback: function (settings, data) {
            //     localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data))
            // },
            // stateLoadCallback: function (settings) {
            //     return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance))
            // }

            // "initComplete": function (settings, json) {
            //     alert('DataTables has finished its initialisation.');
            //     if (props.onDrawCallBack) {
            //         
            //         props.onDrawCallBack(settings);
            //     }
            // }
        }

        if (props.isDragDropEnabled) {
            options.rowReorder = {
                dataSrc: props.rowKey,
                selector: 'td.reorder.enabled',
                update: false
            };
            options.select = true;
            options.fnRowCallback = function (nRow) {
                nRow.setAttribute('id', "row_" + nRow._DT_RowIndex); //Initialize row id for every row
            };
        }

        options.createdRow = function (row, data, dataIndex, cells) {
            if (data.isRead !== null && data.isRead === false) {
                $(row).addClass("bold");
            }
            if (data.isApprove !== null && data.status !== null && data.status === 1 && data.isApprove === true) {
                $(row).addClass("approved");
            }
            if (data.isDefault !== null && data.isDefault === true) {
                $(row).addClass("isdefault");
            }
            if (props.isRowClickEnabled) {
                $(row).addClass("cursor-pointer");
            }

        }

        if (props.isServerSide) {
            options.drawCallback = function (settings) {
                // call callback function on every dataTable redraw
                if (props.onDrawCallBack) {
                    // console.log(settings)
                    props.onDrawCallBack(settings);
                }
            }
        }

        const table = $dtable.DataTable(options);
        // if (props.isServerSide) {
        //     // table.ajax.reload(null, false);

        //     setInterval(function () {
        //         table.ajax.reload(null, false); // user paging is not reset on reload
        //     }, 30000);
        // }

        // var selectedRow = table.rows('.selected');
        // console.log("adasdad", selectedRow)
        // if (selectedRow && selectedRow.data()?.length > 0 && selectedRow.data()[0]?.subCategories && selectedRow.data()[0]?.isSubTableShown && props.columns) {

        //     selectedRow.child(format(props.columns, selectedRow.data().subCategories)).show();

           

        // }

        if (props.headerSearch)
            _this.buildTextboxSearch(table, _this.props.columns);
        else if (props.headerSelectbox) {
            _this.buildSelectboxSearch(table, _this.props.columns);
            /// For cascade input search 
            table.on('draw', function () {
                _this.buildSelectboxSearch(table, _this.props.columns);
            })
        }
        //dt-button selected
        if (props.isDragDropEnabled) {
            table.off('row-reorder').on('row-reorder', function (dragEvent, data, nodes) {
                //if dragdrop event activated and callback event is handled then send sorted date to the page
                if (_this.props.dragDropEndCallBack && data && data.length > 0) {
                    let draggedRows = [];

                    for (var i = 0, ien = data.length; i < ien; i++) {
                        draggedRows.push({ oldId: data[i].oldData, newId: data[i].newData });
                    }
                    _this.props.dragDropEndCallBack(dragEvent, draggedRows);
                }
            });
        }
        if (props.isRowClickEnabled) {
            table.on('click', 'tbody tr', function (e) {
                _this.getUncheckAllCheckBoxes();
                e.currentTarget.classList.toggle("selected"); // highlight selected checkbox's row
                $(e.currentTarget).find('input.ckbox[type="checkbox"]').prop("checked", true);

                if (_this.props.rowClickedCallBack) {
                    var row = table.row($(this)).data();
                    _this.props.rowClickedCallBack(row);
                }
            });
        }

        if (props.isServerSide) {
            table.on('draw.dt', () => {
                if (_this.props.onChangePage) {
                    _this.props.onChangePage(table.page());
                }

                // console.log("current page : ", table.page())
                // _this.props.onChangePage(table.page());
            });

            table.on('draw', function (x) {
                if (props.onDrawCallBack) {
                    // console.log("setting :", table.page.info());
                    props.onDrawCallBack(table.page.info());
                }
            });

            table.on('xhr', function () {
                var json = table.ajax.json();
                // console.log(json.data.length + ' row(s) were loaded')
                // alert(json.data.length + ' row(s) were loaded');
            });
        }


        table.on('click', 'td.dt-control i', function (e) {
            let tr = e.target.closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                e.target.classList.remove('fe-minus-circle');
                e.target.classList.add('fe-plus-circle');
                row.child.hide();
            }
            else {
                e.target.classList.add('fe-minus-circle');
                e.target.classList.remove('fe-plus-circle');
                // Open this row
                if (row.data()?.subCategories && props.columns) {
                    row.child(format(props.columns, row.data().subCategories)).show();
                    // console.log(format(props.columns, row.data().subCategories))
                }

                //             row.child(format(row.data())).show();
                // row.child("sfsdfsdfsd").show();
            }
        });

        table.on('change', 'input.ckboxsub[type=checkbox]', function (e) {
            e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
        });

        table.on('length.dt', function (e, settings, len) {
            if (props.tablePageLengthChanged) {
                props.tablePageLengthChanged(len)
            }
        });

        // var pauseRebuild = false;
        // if (props.isServerSide && _this.props.onChangePage) {
        //     table.on('draw.dt', () => {
        //         if (!pauseRebuild) {
        //             pauseRebuild = true;
        //             _this.props.onChangePage(table.page());
        //             pauseRebuild = false;
        //         }

        //         
        //         // _this.props.onChangePage(table.page());
        //     });
        // }
    }

    // toggleActive = (e) => {
    //     const currentElement = e.currentTarget;

    //     if (currentElement.classList.contains("selected")) {
    //         e.preventDefault();
    //         return;
    //     }

    //     [].forEach.call(currentElement.parentElement.children, function (el) {
    //         currentElement !== el && el.classList.remove("selected");
    //     });

    //     currentElement.classList.toggle("selected");
    // }

    searchKeyUp = (e) => {
        if (dtable.current) {
            const $dtable = $(dtable.current);
            const table = $dtable.DataTable();
            table.search($("#dtableSearch").val()).draw();
        }
    }

    pageLengthChanged = (val) => {
        if (dtable.current) {
            const $dtable = $(dtable.current);
            const table = $dtable.DataTable();
            table.page.len(val.value).draw();
        }
    }

    getUncheckAllCheckBoxes() {
        $(document.querySelectorAll('input.ckbox[type="checkbox"]')).prop("checked", false);
        $(document.querySelectorAll('#dtable tbody tr')).removeClass("selected");
    }

    getCheckedValues() {

        var mainRowsCheckElements = document.querySelectorAll('#dtable tbody tr input.ckbox[type="checkbox"]');
        var subRowsCheckElements = document.querySelectorAll('#dtable tbody tr input.ckboxsub[type="checkbox"]');
        var allCheckElements = mainRowsCheckElements.concat(subRowsCheckElements);

        return Array.from(allCheckElements)
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.value);
    }

    render() {
        return (
            <>
                {this.props.search &&
                    this.props.detailedSearchChildren
                }
                {/* {this.props.search &&
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="row align-items-center mb-2 mt-2">
                                <div className="col-auto" style={{ marginLeft: '10px' }}>
                                    <span className="fa fa-search fs-16" aria-hidden="true" />
                                </div>
                                <div className="col">
                                    <input type="text" id="dtableSearch" className="form-control form-control-sm" onKeyUp={this.searchKeyUp} placeholder="Ara: ASIN, SKU, Ürün Adı" />
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="text-end mb-0 text-wrap">
                                {this.props.detailedSearchChildren && this.props.detailedSearchChildren}
                            </div>
                        </div>

                    </div>
                }
                <div className="collapse pb-3" id="detailedSearchContent">
                    
                    {this.props.detailedSearchChildren &&
                        this.props.detailedSearchChildren.props.searchDetail
                    }
                </div> */}
                <div className="table">
                    <table id="dtable" className="table text-nowrap table-bordered dataTable" ref={dtable} style={{ minWidth: '850px' }}>
                        <thead>
                            <tr className={this.props.headerSearch ? "filters" : ""} >
                                {this.props.columns.map((col, index) => {

                                    return (
                                        <th className={`text-uppercase ${col.checkall ? "text-start" : ""}`}
                                            style={
                                                {
                                                    minWidth: (col.minWidth !== null ? col.minWidth : 'auto'),
                                                    maxWidth: (col.maxWidth !== null ? col.maxWidth : 'auto'),
                                                    verticalAlign: 'middle',
                                                    fontWeight: (col.isBold ? 'bold' : ''),
                                                    position: 'relative'
                                                }
                                            }
                                            key={index}>
                                            {col.data !== null && <input className={`form-control form-control-sm ${col.searchable ? "searchable-column" : ""}`} placeholder={col.title} />}
                                        </th>
                                    )
                                }
                                )}
                            </tr>
                        </thead>
                    </table>
                </div>
            </>);
    }
}

export default DrDataTable;