
import hero from '../../assets/img/hero-img.png'
import sellesmartscreen1 from '../../assets/img/sellesmartscreen1.PNG'
import sellersmartscreen2 from '../../assets/img/sellersmartscreen2.PNG'
import sellersmartscreen3 from '../../assets/img/sellersmartscreen3.PNG'
import lifecycle from '../../assets/img/lifecycle.jpg'
import lifecycle2 from '../../assets/img/lifecycle2.jpg'

import './introduction.scss'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal } from '../../state-management/actions/app.actions';
import { modals } from '../../utils/constants';
import i18next from 'i18next';
import { getURLParams } from '../../utils/utils'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getRouteByKey } from '../../routes'

function useLocationHash() {
  const [hash, setHash] = useState(window.location.hash);
  window.addEventListener("hashchange", () => setHash(window.location.hash));

  return hash;
}


function IntroductionPage() {
  const navigate = useNavigate();
  const hash = useLocationHash();

  const dispatch = useDispatch();
  const { t } = useTranslation('introductionpage');
  const language = useSelector(state => state.settings.language);

  const servicesRef = useRef(null);
  const pricingRef = useRef(null);
  const featuresRef = useRef(null);
  const faqRef = useRef(null);
  const contactRef = useRef(null);


  useEffect(() => {
    if (window.location.hash) {
      
      if (window.location.hash === "#services" ||  window.location.hash === "#/introduction/#services") {
        window.scrollTo({
          top: servicesRef.current.offsetTop,
          behavior: "smooth"
        })
      }
      else if (window.location.hash === "#pricing" ||  window.location.hash === "#/introduction/#pricing") {
        window.scrollTo({
          top: pricingRef.current.offsetTop,
          behavior: "smooth"
        })
      }
      else if (window.location.hash === "#features" ||  window.location.hash === "#/introduction/#features") {
        window.scrollTo({
          top: featuresRef.current.offsetTop,
          behavior: "smooth"
        })
      }
      else if (window.location.hash === "#faq" ||  window.location.hash === "#/introduction/#faq") {
        window.scrollTo({
          top: faqRef.current.offsetTop,
          behavior: "smooth"
        })
      }
      else if (window.location.hash === "#contact" ||  window.location.hash === "#/introduction/#contact") {
        window.scrollTo({
          top: contactRef.current.offsetTop,
          behavior: "smooth"
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash])

  return (
    <div id="introductiondiv">
      <section id="hero" className="d-flex align-items-center">
        <div className="container-fluid" data-aos="fade-up">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1 key={1}> {t('1')}</h1>
              <h2 key={2}>
                {t('2')}
              </h2>
              <div>
                <a href="#pricing" className="btn-get-started scrollto" key={3}>
                  <i style={{ marginRight: "0.5rem" }} className="bi bi-tag-fill" />
                  {t('3')}
                </a>
                <a
                  key={4}
                  className="btn-get-started scrollto"
                  style={{
                    backgroundColor: "green",
                    borderColor: "#ffff",
                    color: "#ffff"
                  }} onClick={() => {
                    // dispatch(setActiveModal({ name: modals.login, isGlobal: true, title: i18next.t('drLogin:title') }));
                    navigate(getRouteByKey("LOGIN"));
                  }
                  }
                >
                  <i
                    style={{ marginRight: "0.5rem" }}
                    className="bi bi-box-arrow-in-right"
                  />
                  {t('4')}
                </a>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay={150}
            >
              <img
                src={hero}
                className="img-fluid animated"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <main id="main">

        <section id="services" ref={servicesRef} className="features">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 key={5}> {t('15')}</h2>
              <p key={6}>
                {t('6')}
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box mt-5 mt-lg-0"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-trending-up" />
                  <h4 key={7}> {t('7')}</h4>
                  <p key={8}>
                    {t('8')}
                  </p>
                </div>
                <div
                  className="icon-box mt-5"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <i className="bx bx-cart-alt" />
                  <h4 key={9}> {t('9')}</h4>
                  <p key={10}>
                    {t('10')}
                  </p>
                </div>
              </div>
              <div
                className="image col-lg-6 order-1 order-lg-2 content "
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img src={sellesmartscreen1} alt="" className="img-fluid" style={{ height: "100%" }} />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="image col-lg-6 order-2 order-lg-1 "

                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img src={sellersmartscreen2} alt="" className="img-fluid" style={{ height: "100%" }} />
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex flex-column align-items-lg-center">


                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <i className="bx bx-box" />
                  <h4 key={11}> {t('11')}</h4>
                  <p key={12}>
                    {t('12')}
                  </p>
                </div>
                <div
                  className="icon-box mt-5"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <i className="bx bx-time" />
                  <h4 key={13}> {t('13')}</h4>
                  <p key={14}>
                    {t('14')}
                  </p>
                </div>

              </div>

            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column align-items-lg-center">
                <div
                  className="icon-box "
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <i className='bx bxs-dashboard'></i>
                  <h4 key={13}> {t('43')}</h4>
                  <p key={14}>
                    {t('44')}
                  </p>
                </div>
                <div
                  className="icon-box mt-5"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <i className='bx bx-purchase-tag-alt' ></i>
                  <h4 key={13}> {t('45')}</h4>
                  <p key={14}>
                    {t('46')}
                  </p>
                </div>
                <div
                  className="icon-box mt-5"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <i className='bx bx-phone'></i>
                  <h4 key={13}> {t('67')}</h4>
                  <p key={14}>
                    {t('68')}
                  </p>
                </div>
              </div>

              <div
                className="image col-lg-6 order-1 order-lg-2 "
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img src={sellersmartscreen3} alt="" className="img-fluid" style={{ height: "100%" }} />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="image col-lg-6 order-2 order-lg-1 text-center  justify-content-center "

                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <img src={language === "tr" ? lifecycle : lifecycle2} alt="" className="img-fluid" style={{ height: "100%" }} />
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex flex-column align-items-lg-center">


                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <i className="bx bx-box" />
                  <h4 key={11}> {t('11')}</h4>
                  <p key={12}>
                    {t('12')}
                  </p>
                </div>
                <div
                  className="icon-box mt-5"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <i className="bx bx-time" />
                  <h4 key={13}> {t('13')}</h4>
                  <p key={14}>
                    {t('14')}
                  </p>
                </div>

              </div>

            </div>

          </div>
        </section>

        <section id="features" ref={featuresRef} className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 key={15}> {t('5')}</h2>
            </div>
            <div className="row gy-6">
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"

                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"
                      ></path>
                    </svg>
                    <i className="bi bi-check-circle" />
                  </div>
                  <h4>
                    <a href="" key={17}>
                      {t('17')}
                    </a>
                  </h4>
                  <p key={18}>
                    {t('18')}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={200}
              >
                <div className="icon-box iconbox-orange ">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"
                      ></path>
                    </svg>
                    <i className="bi bi-stopwatch" />
                  </div>
                  <h4>
                    <a href="" key={19}>
                      {t('19')}
                    </a>
                  </h4>
                  <p key={20}>
                    {t('20')}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box iconbox-green">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781"
                      ></path>
                    </svg>
                    <i className="bx bx-dollar-circle" />
                  </div>
                  <h4>
                    <a href="" key={21}>
                      {t('21')}
                    </a>
                  </h4>
                  <p key={22}>
                    {t('22')}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <div className="icon-box iconbox-yellow">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,500.4755392045468,300,503.46388370962813"
                      ></path>
                    </svg>
                    <i className="bi bi-plus-circle" />
                  </div>
                  <h4>
                    <a href="" key={23}>
                      {t('23')}
                    </a>
                  </h4>
                  <p key={24}>
                    {t('24')}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <div className="icon-box iconbox-teal">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,532.3542879108572C369.38199826031484,532.3153073249985,429.10787420159085,491.63046689027357,474.5244479745417,439.17860296908856C522.8885846962883,383.3225815378663,569.1668002868075,314.3205725914397,550.7432151929288,242.7694973846089C532.6665558377875,172.5657663291529,456.2379748765914,142.6223662098291,390.3689995646985,112.34683881706744C326.66090330228417,83.06452184765237,258.84405631176094,53.51806209861945,193.32584062364296,78.48882559362697C121.61183558270385,105.82097193414197,62.805066853699245,167.19869350419734,48.57481801355237,242.6138429142374C34.843463184063346,315.3850353017275,76.69343916112496,383.4422959591041,125.22947124332185,439.3748458443577C170.7312796277747,491.8107796887764,230.57421082200815,532.3932930995766,300,532.3542879108572"
                      ></path>
                    </svg>
                    <i className="bi bi-tags" />
                  </div>
                  <h4>
                    <a href="" key={25}>
                      {t('25')}
                    </a>
                  </h4>
                  <p key={26}>
                    {t('26')}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box iconbox-red">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
                      ></path>
                    </svg>
                    <i className='bx bx-cloud-light-rain'></i>
                  </div>
                  <h4>
                    <a href="" key={27}>

                      {t('27')}
                    </a>
                  </h4>
                  <p key={28}>
                    {t('28')}
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box iconbox-teal">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
                      ></path>
                    </svg>
                    <i className='bx bxs-cube-alt'></i>
                  </div>
                  <h4>
                    <a href="" key={27}>

                      {t('47')}
                    </a>
                  </h4>
                  <p key={28}>
                    {t('48')}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box iconbox-red">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
                      ></path>
                    </svg>
                    <i className='bx bx-check-square'></i>
                  </div>
                  <h4>
                    <a href="" key={27}>

                      {t('49')}
                    </a>
                  </h4>
                  <p key={28}>
                    {t('50')}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box iconbox-green">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
                      ></path>
                    </svg>
                    <i className='bx bx-store-alt'></i>

                  </div>
                  <h4>
                    <a href="" key={27}>

                      {t('51')}
                    </a>
                  </h4>
                  <p key={28}>
                    {t('52')}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box iconbox-orange">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
                      ></path>
                    </svg>
                    <i className='bx bx-basket'></i>
                  </div>
                  <h4>
                    <a href="" key={27}>

                      {t('53')}
                    </a>
                  </h4>
                  <p key={28}>
                    {t('54')}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box iconbox-yellow">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
                      ></path>
                    </svg>
                    <i className='bx bxs-hourglass-top'></i>
                  </div>
                  <h4>
                    <a href="" key={27}>

                      {t('55')}
                    </a>
                  </h4>
                  <p key={28}>
                    {t('56')}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
                      ></path>
                    </svg>
                    <i className='bx bxs-cylinder'></i>
                  </div>
                  <h4>
                    <a href="" key={27}>

                      {t('57')}
                    </a>
                  </h4>
                  <p key={28}>
                    {t('58')}
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={300}
              >
                <div className="icon-box iconbox-red">
                  <div className="icon">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth={0}
                        fill="#f5f5f5"
                        d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
                      ></path>
                    </svg>
                    <i className='bx bxs-box'></i>
                  </div>
                  <h4>
                    <a href="" key={27}>

                      {t('59')}
                    </a>
                  </h4>
                  <p key={28}>
                    {t('60')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="pricing" ref={pricingRef} className="pricing section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 key={29}> {t('29')}</h2>
              <p key={30}>
                {t('30')}
              </p>
            </div>
            <>
              {/* Pricing # */}
              <div className="pricing">

                <div className="pricing-table table-responsive">
                  <table className="table">
                    {/* Heading */}
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>
                          Free
                          {/* <span className="ptable-star red">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star-half-o" />
                              <i className="fa fa-star-o" />
                            </span> */}
                          <span className="ptable-price">(Trail)</span>
                        </th>
                        <th className="highlight">
                          Basic
                          {/* <span className="ptable-star green">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star-o" />
                            </span> */}
                          <span className="ptable-price">$49</span>
                        </th>
                        <th>
                          Standart
                          {/* <span className="ptable-star lblue">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </span> */}
                          <span className="ptable-price">$59</span>
                        </th>
                        <th>
                          Premİum
                          {/* <span className="ptable-star lblue">
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                              <i className="fa fa-star" />
                            </span> */}
                          <span className="ptable-price">$69</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="ptable-title">
                            {/* <i className="fa fa-hdd-o" />  */}
                            <i className="fa fa-cube" aria-hidden="true"></i> Asİn Sayısı
                          </span>
                        </td>
                        <td>
                          {/* Icon */}
                          {/* <i className="fa fa-hdd-o red" /> */}
                          <i className="fa fa-cube" aria-hidden="true"></i> 5.000
                        </td>
                        <td>
                          {/* Icon */}
                          {/* <i className="fa fa-hdd-o green" /> */}
                          <i className="fa fa-cube" aria-hidden="true"></i>  10.000
                        </td>
                        <td>
                          {/* Icon */}
                          {/* <i className="fa fa-hdd-o lblue" /> */}
                          <i className="fa fa-cube" aria-hidden="true"></i>  25.000
                        </td>
                        <td>
                          {/* Icon */}
                          {/* <i className="fa fa-hdd-o lblue" /> */}
                          <i className="fa fa-cube" aria-hidden="true"></i>  50.000
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="ptable-title">
                            <i className="fa fa-globe" aria-hidden="true"></i>
                            Desteklenen Ülkeler
                          </span>
                        </td>
                        <td>
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          US
                          Canada
                          Mexico
                          UAE
                          Saudi Arabia
                          Japan
                          Singapore
                          France
                          Germany
                          Italy
                          Spain
                          Netherlands
                          UK
                          Belgium
                          Poland
                          Sweden
                          Turkey
                          Australia
                        </td>
                        <td>
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          US
                          Canada
                          Mexico
                          UAE
                          Saudi Arabia
                          Japan
                          Singapore
                          France
                          Germany
                          Italy
                          Spain
                          Netherlands
                          UK
                          Belgium
                          Poland
                          Sweden
                          Turkey
                          Australia
                        </td>
                        <td>
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          US
                          Canada
                          Mexico
                          UAE
                          Saudi Arabia
                          Japan
                          Singapore
                          France
                          Germany
                          Italy
                          Spain
                          Netherlands
                          UK
                          Belgium
                          Poland
                          Sweden
                          Turkey
                          Australia
                        </td>
                        <td>
                          <i className="fa fa-globe" aria-hidden="true"></i>
                          US
                          Canada
                          Mexico
                          UAE
                          Saudi Arabia
                          Japan
                          Singapore
                          France
                          Germany
                          Italy
                          Spain
                          Netherlands
                          UK
                          Belgium
                          Poland
                          Sweden
                          Turkey
                          Australia
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="ptable-title">
                            <i className="fa fa-headphones" /> Müşterİ Temsİlcİsİ
                          </span>
                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>

                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>

                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>

                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>

                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="ptable-title">
                            <i className="fa fa-amazon" aria-hidden="true"></i> Amozonda  E-Tİcaret
                          </span>
                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>

                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>

                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>

                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>

                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="ptable-title">
                            <i className="fa fa-money" aria-hidden="true"></i> Gelİşmİş Fiyatlandırma
                          </span>
                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>
                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>
                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>
                        </td>
                        <td>
                          <i className="fa fa-check green" aria-hidden="true"></i>
                        </td>
                      </tr>
                      {/* Buttons */}
                      <tr>
                        <td>&nbsp;</td>
                        <td className="bg-mor">
                          <button className="btn">
                            Buy
                          </button>
                        </td>
                        <td className="bg-mor">
                          <button className="btn">
                            Buy
                          </button>
                        </td>
                        <td className="bg-mor">
                          <button className="btn">
                            Buy
                          </button>
                        </td>
                        <td className="bg-mor">
                          <button className="btn">
                            Buy
                          </button>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </>

          </div>
        </section>

        <section id="faq" ref={faqRef} className="faq">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 key={31}> {t('31')}</h2>
              <p key={32}> {t('32')}</p>
            </div>
            <div className="faq-list">
              <ul>
                <li data-aos="fade-up" data-aos-delay={100}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target="#faq-list-1"
                    key={33}
                  >
                    {t('33')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-1"
                    className="collapse show"
                    data-bs-parent=".faq-list"
                  >
                    <p key={34}>
                      {t('34')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    className="collapsed"
                    key={35}
                  >
                    {t('35')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('36')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    className="collapsed"
                    key={35}
                  >
                    {t('65')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('66')}
                    </p>
                  </div>
                </li>


                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    className="collapsed"
                    key={35}
                  >
                    {t('61')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('62')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    className="collapsed"
                    key={35}
                  >
                    {t('63')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('64')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-6"
                    className="collapsed"
                    key={35}
                  >
                    {t('72')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-6"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('73')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-7"
                    className="collapsed"
                    key={35}
                  >
                    {t('74')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-7"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('75')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-8"
                    className="collapsed"
                    key={35}
                  >
                    {t('76')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-8"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('77')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-9"
                    className="collapsed"
                    key={35}
                  >
                    {t('78')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-9"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('79')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-10"
                    className="collapsed"
                    key={35}
                  >
                    {t('80')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-10"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('81')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-11"
                    className="collapsed"
                    key={35}
                  >
                    {t('82')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-11"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('83')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-12"
                    className="collapsed"
                    key={35}
                  >
                    {t('84')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-12"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('85')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-13"
                    className="collapsed"
                    key={35}
                  >
                    {t('86')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-13"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('87')}
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay={200}>
                  <i className="bx bx-help-circle icon-help" />{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-14"
                    className="collapsed"
                    key={35}
                  >
                    {t('88')}
                    <i className="bx bx-chevron-down icon-show" />
                    <i className="bx bx-chevron-up icon-close" />
                  </a>
                  <div
                    id="faq-list-14"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p key={36}>
                      {t('89')}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section id="contact" ref={contactRef} className="contact section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 key={37}> {t('37')}</h2>
              <p key={38}> {t('38')}</p>
            </div>
            <div className="row">
              <div className="col-lg-6" >
                <div className="info-box mb-4">
                  <i className="bx bx-map" />
                  <h3 key={39}> {t('39')}</h3>
                  <p>
                    DUDULLU OSB MAH. DES-2 CAD. TEKNOPARK BINASI NO: 8 IÇ KAPI NO:
                    112 ÜMRANIYE/&nbsp;ISTANBUL
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-envelope" />
                  <h3 key={40}> {t('40')}</h3>
                  <p>info@metechs.com.tr</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-phone-call" />
                  <h3 key={41}> {t('41')}</h3>
                  <p>+1 601 759 15 80</p>
                  <p>+90 549 743 82 06</p>

                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 ">
                <iframe title="aaaaa"
                  className="mb-4 mb-lg-0"
                  src="https://www.google.com/maps/embed/v1/place?q=DUDULLU+OSB+MAH.+DES-2+CAD.+TEKNOPARK+BINASI+NO:+8+IÇ+KAPI+NO:+112+ÜMRANIYE/ ISTANBUL&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                  frameBorder={0}
                  style={{ border: 0, width: "100%", height: 362 }}
                  allowFullScreen=""
                />
              </div>
              <div className="col-lg-6">
                <form
                  action="forms/contact.php"
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required=""
                      />
                    </div>
                    <div className="col-md-6 form-group ">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required=""
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      placeholder="Message"
                      required=""
                      defaultValue={""}
                    />
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message" />
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
        <div className='container' style={{ backgroundColor: "#5846f9", textAlign: "center", borderRadius: "10px", padding: "20px" }}>
          <p style={{ color: "white", fontSize: "22px", fontWeight: "bold" }}>
            {t('69')}
          </p>
          <p style={{ color: "white", fontSize: "22px", fontWeight: "bold" }}>
            {t('70')}
          </p>
          <a href="#pricing" className="bg-mor" style={{ color: "white", border: "1px solid", padding: "10px", borderRadius: "4px" }} >
            {t('71')} <i className='bx bxs-label'></i>
          </a>
        </div>
      </main>
    </div>
  )
}

export default IntroductionPage;