import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import logo from '../../../assets/img/brand/logo.png'
import { ReactComponent as Logo } from '../../../assets/img/brand/logo.png'

import { logoutUser, setCustomerInfoAuthorization } from "../../../state-management/actions/customer.actions";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModal, withActionPromise } from "../../../state-management/actions/app.actions";
import { buttonVariants, consistantCapitalizeFirstLetter, modals, sweetAlertVariants } from "../../../utils/constants";
import i18n from "../../../i18n";
import { setLanguage, setTheme, setWarehouseHomePageSelections, setWarehouseOrderPageSelections } from "../../../state-management/actions/settings.actions";
import i18next from '../../../i18n'
import './dr-header.component.scss'
import { useTranslation } from "react-i18next";
import DrStoreDropdown from "../StoreDropdown/dr-store-dropdown.component";
import { IsMarketCreateStep, IsSmartPricerCreateStep, getRouteByKey } from "../../../routes";
import { useLocation, useNavigate } from "react-router-dom";
import { DrButtonDropdown, DrInput, DrNavigateLink, DrSweetAlertButton } from "../../component-index";
import history from "../../../history";
import Restricted from "../../../security/Restricted";
import { clearSmartPricerState } from "../../../state-management/actions/create-smartpricer-filter.actions";

import avatar from '../../../assets/img/users/profile3.png'
import { ReactSVG } from "react-svg";
import { toggleTheme } from "../../../utils/theme-change";
import { openFullscreen, openFullscreen2, toggleSidemenu } from "../../../utils/menu";
import classNames from "classnames";
import { clearCreatePackagesState } from "../../../state-management/actions/create-customer-packages.actions";
import { getWarehouses, getWarehouseShippings } from "../../../state-management/actions/warehouses.actions";
import { flaggedMarketOptions } from "../Constants/dr-constants.component";
import { Formik } from "formik";
import { render as HtmlRender } from '@react-email/render';

function DrHeader() {

    const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN,
        REACT_APP_ROLES_STORE, REACT_APP_ROLES_GUEST,
        REACT_APP_ROLES_USER, REACT_APP_ROLES_ORDERS,
        REACT_APP_ROLES_INVENTORY, REACT_APP_ROLES_REPORT, REACT_APP_ROLES_LOGISTICS } = process.env;

    const navigate = useNavigate();
    const { t } = useTranslation('drHeader');

    const location = useLocation();
    const dispatch = useDispatch();
    const isLoggedin = useSelector(state => state.customer?.isLoggedin);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const language = useSelector(state => state.settings.language);
    const customerInfo = useSelector(state => state.customer.customerInfo);

    const [warehouseMarketOptions, setWarehouseMarketOptions] = useState(null);
    const allWarehouses = useSelector(state => state.warehouses.allWarehouses);
    const allWarehouseShippings = useSelector(state => state.warehouses.allWarehouseShippings);
    const [warehousesData, setWarehousesData] = useState([]);
    const warehouseOrdersPageSelections = useSelector(state => state.settings?.pageSelections?.warehouseOrdersPageSelections);
    const warehouseHomePageSelections = useSelector(state => state.settings?.pageSelections?.warehouseHomePageSelections);

    const logOut = async () => {
        Cookies.remove('AuthToken', { domain: process.env.REACT_APP_HOST_URL });
        history.entries = [];
        history.index = -1;

        await Promise.all([
            dispatch(withActionPromise(logoutUser()))
        ]).then((e) => {
            // navigate(getRouteByKey("LOGIN"));
            navigate(getRouteByKey("INTRODUCTION"));
        });
    }

    //const [isLoadingLanguage, setLoadingLanguage] = useState(false);
    useEffect(() => {
        if (language && language !== '') {
            const load = async () => {
                // setLoadingLanguage(true);
                await i18n.changeLanguage(language);
                // setLoadingLanguage(false);
                Cookies.set('Culture', language);
            };
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);



    useEffect(() => {
        if ((customerInfo?.role?.includes(REACT_APP_ROLES_STORE) || customerInfo?.role?.includes(REACT_APP_ROLES_SUPERADMIN))) {
            if (!allWarehouses || (allWarehouses && allWarehouses.length === 0)) {
                dispatch(withActionPromise(getWarehouses()));
            }
            if (!allWarehouseShippings || (allWarehouseShippings && allWarehouseShippings.length === 0)) {
                dispatch(withActionPromise(getWarehouseShippings()));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ((customerInfo?.role?.includes(REACT_APP_ROLES_STORE) || customerInfo?.role?.includes(REACT_APP_ROLES_SUPERADMIN)) && allWarehouses) {
            let data = Object.assign([], allWarehouses);
            data.forEach(warehouse => {
                warehouse.warehouseShippings = Object.assign([], allWarehouseShippings && allWarehouseShippings.find(x => x.wareHouseId === warehouse.id)?.shippings);

            });
            setWarehousesData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allWarehouses, allWarehouseShippings]);

    useEffect(() => {
        if (warehousesData && warehousesData.length > 0) {
            var selectedWarehousesMarkets = [];
            warehousesData.forEach(warehouse => {
                let warehouseSelected = false;
                if (warehouse.warehouseShippings && warehouse.warehouseShippings.length > 0) {
                    warehouse.warehouseShippings.forEach(shipment => {
                        if (shipment.isSelected) {
                            warehouseSelected = true;
                        }
                    });
                    if (warehouseSelected) {
                        if (!selectedWarehousesMarkets.find(x => x.value === warehouse.marketId)) {
                            selectedWarehousesMarkets = selectedWarehousesMarkets.concat(flaggedMarketOptions.find(x => x.value === warehouse.marketId));
                        }
                    }
                }
            });
            selectedWarehousesMarkets?.splice(0, 0, { value: "-1", label: i18next.t(('constants:countries.all')), icon: <i className={'w-6 me-2 fw-bold bi bi-globe'} /> });
            setWarehouseMarketOptions(selectedWarehousesMarkets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehousesData]);

    var headerClass = classNames({
        'app-header': true,
        'p-0': customerInfo?.role?.includes(REACT_APP_ROLES_USER) || customerInfo?.role?.includes(REACT_APP_ROLES_GUEST)
    });
    var logoNavigateKey = classNames({
        'HOME': isLoggedin && !(customerInfo?.role?.includes(REACT_APP_ROLES_USER) || customerInfo?.role?.includes(REACT_APP_ROLES_GUEST) || customerInfo?.role?.includes(REACT_APP_ROLES_STORE)),
        'LOGIN': !isLoggedin,
        'MARKET_SELECTION': isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_USER), // user
        'MAIN_PACKAGES': isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_GUEST), //guest
        'WAREHOUSE_HOME': isLoggedin && customerInfo?.role?.includes(REACT_APP_ROLES_STORE) // store
    });


    const marketOptionClickCallBack = (value) => {
        dispatch(withActionPromise(setWarehouseOrderPageSelections({ dateInterval: warehouseOrdersPageSelections?.dateInterval, orderStatus: warehouseOrdersPageSelections?.orderStatus, marketOption: value })));
        dispatch(withActionPromise(setWarehouseHomePageSelections({ marketOption: value, dateInterval: warehouseHomePageSelections.dateInterval })));
    };

    return (
        <>
            <header className={headerClass}>
                <div className="main-header-container container-fluid">
                    <div className="header-content-left">
                        <div className="header-element">

                            <Restricted allowedTo={[
                                REACT_APP_ROLES_SUPERADMIN,
                                REACT_APP_ROLES_ADMIN,
                                REACT_APP_ROLES_ORDERS,
                                REACT_APP_ROLES_STORE,
                                REACT_APP_ROLES_LOGISTICS,
                                REACT_APP_ROLES_INVENTORY,
                                REACT_APP_ROLES_REPORT
                            ]}>
                                <div className="horizontal-logo m-auto">
                                    <DrNavigateLink className="header-logo" navigateKey={logoNavigateKey}>
                                        <ReactSVG className="desktop-logo" src="../assets/images/brand-logos/logo.svg" />
                                        <ReactSVG className="desktop-dark" src="../assets/images/brand-logos/logo.svg" />
                                        <img src="../assets/images/brand-logos/toggle-logo.png" alt="logo" className="toggle-logo" />
                                        <img src="../assets/images/brand-logos/toggle-dark.png" alt="logo" className="toggle-dark" />
                                        <img src="../assets/images/brand-logos/desktop-white.png" alt="logo" className="desktop-white" />
                                        <img src="../assets/images/brand-logos/toggle-white.png" alt="logo" className="toggle-white" />
                                    </DrNavigateLink>
                                </div>
                            </Restricted>
                            <Restricted allowedTo={[
                                REACT_APP_ROLES_USER,
                                REACT_APP_ROLES_GUEST
                            ]}>
                                <div className="horizontal-logo-no-sidebar m-auto">
                                    <DrNavigateLink className="header-logo" navigateKey={logoNavigateKey}>
                                        <ReactSVG className="desktop-logo" src="../assets/images/brand-logos/logo.svg" />
                                        <ReactSVG className="desktop-dark" src="../assets/images/brand-logos/logo.svg" />
                                        <img src="../assets/images/brand-logos/toggle-logo.png" alt="logo" className="toggle-logo" />
                                        <img src="../assets/images/brand-logos/toggle-dark.png" alt="logo" className="toggle-dark" />
                                        <img src="../assets/images/brand-logos/desktop-white.png" alt="logo" className="desktop-white" />
                                        <img src="../assets/images/brand-logos/toggle-white.png" alt="logo" className="toggle-white" />
                                    </DrNavigateLink>
                                </div>
                            </Restricted>

                            {!isLoggedin &&
                                <div className="horizontal-logo-no-sidebar m-auto">
                                    <DrNavigateLink className="header-logo" navigateKey={logoNavigateKey}>
                                        <ReactSVG className="desktop-logo" src="../assets/images/brand-logos/logo.svg" />
                                        <ReactSVG className="desktop-dark" src="../assets/images/brand-logos/logo.svg" />
                                        <img src="../assets/images/brand-logos/toggle-logo.png" alt="logo" className="toggle-logo" />
                                        <img src="../assets/images/brand-logos/toggle-dark.png" alt="logo" className="toggle-dark" />
                                        <img src="../assets/images/brand-logos/desktop-white.png" alt="logo" className="desktop-white" />
                                        <img src="../assets/images/brand-logos/toggle-white.png" alt="logo" className="toggle-white" />
                                    </DrNavigateLink>
                                </div>
                            }

                        </div>
                        <Restricted allowedTo={[
                            REACT_APP_ROLES_SUPERADMIN,
                            REACT_APP_ROLES_ADMIN,
                            REACT_APP_ROLES_ORDERS,
                            REACT_APP_ROLES_STORE,
                            REACT_APP_ROLES_LOGISTICS,
                            REACT_APP_ROLES_INVENTORY,
                            REACT_APP_ROLES_REPORT
                        ]}>
                            <div className="header-element">
                                {isLoggedin &&
                                    <span aria-label="Hide Sidebar" className="sidemenu-toggle header-link animated-arrow hor-toggle horizontal-navtoggle"
                                        onClick={() => {
                                            toggleSidemenu();
                                        }}><span />
                                    </span>
                                }
                            </div>
                        </Restricted>

                        {isLoggedin &&
                            <Restricted allowedTo={[
                                REACT_APP_ROLES_SUPERADMIN,
                                REACT_APP_ROLES_ADMIN
                            ]}>
                                <Formik
                                    initialValues={{ searchText: "" }}
                                    validateOnBlur={true}
                                    // onSubmit={handleSubmit}
                                    // validationSchema={customerInfoSchema}
                                    enableReinitialize={true}
                                    validateOnMount={true}
                                >
                                    {({
                                        isValid,
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        setTouched,
                                        setFieldValue
                                    }) => (

                                        <div className="main-header-center  d-none d-lg-block header-link">
                                            <input type="text" className="form-control" id="typehead" placeholder="Search for results..." autoComplete="off"
                                                value={values.searchText || ""}
                                                onChange={(e) => {
                                                    setFieldValue("searchText", e.target.value || "")
                                                }}
                                                onBlur={() => { setTouched({ ...touched, 'searchText': true }) }}
                                            />
                                            {/* <DrInput value={values.searchText} className={`form-control`}
                                                placeholder={t('searchText')}
                                                name="searchText"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'searchText': true }) }}
                                                isInvalid={(touched.searchText && errors.searchText)}
                                                isValid={touched.searchText && !errors.searchText}
                                                validationText={errors.searchText}
                                            /> */}
                                            <button type="button" aria-label="button" className="btn pe-1 cursor-pointer" value={values.searchText}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    var asinRegex = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{10}$/;
                                                    var orderNumberRegex = /^\d{3}-\d{7}-\d{7}$/;
                                                    if (asinRegex.test(values.searchText.toString().toUpperCase())) {
                                                        let refresh = window.location.hash.includes("/product-detail")
                                                        navigate('/product-detail/' + values.searchText);
                                                        if (refresh) {
                                                            window.location.reload();
                                                        }
                                                    }
                                                    else if (orderNumberRegex.test(values.searchText)) {
                                                        let refresh = window.location.hash.includes("/product-detail")
                                                        navigate('/order-detail/' + values.searchText);
                                                        if (refresh) {
                                                            window.location.reload();
                                                        }
                                                    }
                                                    else {
                                                        let refresh = window.location.hash.includes("/product-detail")
                                                        navigate('/inventory/s/' + values.searchText);
                                                        if (refresh) {
                                                            window.location.reload();
                                                        }
                                                    }
                                                }}>
                                                <i className="fe fe-search" aria-hidden="true" />
                                            </button>
                                        </div>
                                    )
                                    }
                                </Formik>
                            </Restricted>
                        }
                    </div>

                    <div className="header-content-right">
                        {/* {isLoggedin &&
                            <Restricted allowedTo={[
                                REACT_APP_ROLES_SUPERADMIN,
                                REACT_APP_ROLES_ADMIN
                            ]}>
                                <div className="header-element header-search d-lg-none d-block m-auto">
                                    <a aria-label="anchor" href="javascript:void(0);" className="header-link" data-bs-toggle="modal" data-bs-target="#searchModal">
                                        <i className="fe fe-search header-link-icon mt-1" />
                                    </a>
                                </div>
                            </Restricted>
                        } */}


                        {isLoggedin &&
                            <Restricted allowedTo={[
                                REACT_APP_ROLES_SUPERADMIN,
                                REACT_APP_ROLES_ADMIN
                            ]}>
                                {!IsMarketCreateStep(location.pathname) &&
                                    <div className="header-element">
                                        <DrStoreDropdown className="header-link" disabled={selectedStore && IsSmartPricerCreateStep(location.pathname)}></DrStoreDropdown>
                                    </div>
                                }
                            </Restricted>
                        }


                        {isLoggedin &&
                            <Restricted allowedTo={[
                                REACT_APP_ROLES_SUPERADMIN,
                                REACT_APP_ROLES_STORE
                            ]}>
                                <div className="header-element">
                                    <div className="header-link">
                                        <DrButtonDropdown className=" shadow" options={warehouseMarketOptions}
                                            optionClickCallBack={marketOptionClickCallBack}
                                            selectedValue={warehouseOrdersPageSelections?.marketOption || (warehouseMarketOptions?.length > 0 ? warehouseMarketOptions[0].value : "")}
                                            variant={buttonVariants.teal}
                                        >
                                        </DrButtonDropdown>
                                    </div>

                                </div>
                            </Restricted>
                        }




                        {/* 
                        <div className="header-element">
                            <div aria-label="anchor" className="header-link layout-setting pulse pulse-success">
                                <div className="btn-group">
                                    <button className={`btn btn-teal btn-wave waves-effect waves-light shadow`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(setCustomerInfoAuthorization([{
                                                customerMainSellerId: selectedStore?.customerMainSellerId,
                                                roleIds: [REACT_APP_ROLES_GUEST]
                                            }]))
                                        }}
                                    >Guest</button>
                                    <button className={`btn btn-teal btn-wave waves-effect waves-light shadow`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(setCustomerInfoAuthorization([{
                                                customerMainSellerId: selectedStore?.customerMainSellerId,
                                                roleIds: [REACT_APP_ROLES_USER]
                                            }]))
                                        }}
                                    >User</button>
                                    <button className={`btn btn-teal btn-wave waves-effect waves-light shadow`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(setCustomerInfoAuthorization([{
                                                customerMainSellerId: selectedStore?.customerMainSellerId,
                                                roleIds: [REACT_APP_ROLES_ADMIN]
                                            }]))
                                        }}
                                    >Admin</button>

                                </div>
                            </div>

                        </div> */}




                        {isLoggedin &&
                            <Restricted allowedTo={[
                                REACT_APP_ROLES_USER,
                                REACT_APP_ROLES_GUEST
                            ]}>
                                <div className="header-element">
                                    <div aria-label="anchor" className="header-link layout-setting">
                                        <div className="row">
                                            <div className="col-auto pulse pulse-success">
                                                <button className={`btn btn-teal btn-wave waves-effect waves-light shadow`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(withActionPromise(clearCreatePackagesState()));
                                                        navigate(getRouteByKey("MAIN_PACKAGES"));
                                                    }}
                                                >Paketler</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Restricted>
                        }

                        {isLoggedin &&
                            <Restricted allowedTo={[
                                REACT_APP_ROLES_USER
                            ]}>
                                <div className="header-element">
                                    <div aria-label="anchor" className="header-link layout-setting">
                                        <div className="row">
                                            <div className="col-auto pulse pulse-success">
                                                <button className={`btn btn-teal btn-wave waves-effect waves-light shadow`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(withActionPromise(clearCreatePackagesState()));
                                                        navigate(getRouteByKey("MARKET_SELECTION"));
                                                    }}
                                                >Market Selection</button>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </Restricted>
                        }



                        <div className="header-element country-selector cursor-pointer">
                            <div className="header-link dropdown-toggle" data-bs-auto-close="outside" data-bs-toggle="dropdown">
                                <span className="avatar avatar-md lh-1" >
                                    {language && <i className={`w-6 flag  ${language === "tr" ? "flag-tr" : "flag-us"}`}></i>}
                                    {!language && <i className="fe fe-globe"></i>}
                                </span>
                            </div>

                            <ul className="main-header-dropdown dropdown-menu dropdown-menu-end" data-popper-placement="none">

                                <li className="dropdown-item">
                                    <div className="d-flex w-100" onClick={() => dispatch(setLanguage("tr"))}>
                                        <i className="me-2 flag flag-tr"></i>
                                        {t('turkish')}
                                    </div>
                                </li>
                                <li className="dropdown-item">
                                    <div className="d-flex w-100" onClick={() => dispatch(setLanguage("en"))}>
                                        <i className="me-2 flag flag-us"></i>
                                        {t('english')}
                                    </div>
                                </li>

                            </ul>
                        </div>

                        <div className="header-element header-theme-mode cursor-pointer">
                            <div aria-label="anchor" className="header-link layout-setting"
                                onClick={() => {
                                    toggleTheme();
                                    // if (isLoggedin) {
                                    //     dispatch(setTheme("dark"));
                                    // }
                                }}>
                                <span className="light-layout pt-1">
                                    <i className="fe fe-moon header-link-icon" />
                                </span>
                                <span className="dark-layout pt-1">
                                    <i className="fe fe-sun header-link-icon" />
                                </span>
                            </div>
                        </div>

                        {/* <div className="header-element header-fullscreen">
                            <a aria-label="anchor" onclick="openFullscreen();" href="#" className="header-link">
                                <i className="fe fe-minimize full-screen-open header-link-icon" />
                                <i className="fe fe-minimize-2 full-screen-close header-link-icon d-none" />
                            </a>
                             <span className="header-link cursor-pointer " >
                                <i className="fe fe-minimize full-screen-open header-link-icon fullscreen-button" />
                                <i className="fe fe-minimize-2 full-screen-close header-link-icon d-none" />
                            </span> 
                        </div> */}


                        {/* <div className="header-element message-dropdown">
                            <a aria-label="anchor" href="javascript:void(0);" className="header-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" id="messageDropdown2" aria-expanded="false">
                                <i className="fe fe-message-square header-link-icon" />
                                <span className="w-9 h-9 p-0 bg-danger rounded-pill header-icon-badge pulse pulse-danger" id="message-icon-badge" />
                            </a>
                            <div className="main-header-dropdown dropdown-menu dropdown-menu-end" data-popper-placement="none">
                                <div className="p-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="mb-0 fs-17 fw-semibold">Messages</p>
                                        <span className="badge bg-secondary-transparent" id="message-data">5 Unread</span>
                                    </div>
                                </div>
                                <div className="dropdown-divider" />
                                <ul className="list-unstyled mb-0" id="header-message-scroll">
                                    <li className="dropdown-item">
                                        <div className="d-flex align-items-start">
                                            <div className="pe-2">
                                                <img src="../assets/images/faces/1.jpg" alt="img" className="avatar avatar-md avatar-rounded" />
                                            </div>
                                            <div className="w-100">
                                                <div className="flex-grow-1 d-flex align-items-centermy-auto">
                                                    <div>
                                                        <h6 className="mb-0 fw-semibold fs-14"><a href="chat.html">Peter Theil</a></h6>
                                                    </div>
                                                    <div className="ms-auto text-end">
                                                        <p className="text-muted mb-0">
                                                            6:45am
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 d-flex align-items-centermy-auto">
                                                    <div>
                                                        <span className="text-muted fw-normal fs-12">Commented on file Guest list....</span>
                                                    </div>
                                                    <div className="ms-auto text-end">
                                                        <a aria-label="anchor" href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close2"><i className="ti ti-x fs-16" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="dropdown-item">
                                        <div className="d-flex align-items-start">
                                            <div className="pe-2">
                                                <img src="../assets/images/faces/15.jpg" alt="img" className="avatar avatar-md avatar-rounded" />
                                            </div>
                                            <div className="w-100">
                                                <div className="flex-grow-1 d-flex align-items-centermy-auto">
                                                    <div>
                                                        <h6 className="mb-0 fw-semibold fs-14"><a href="chat.html">Abagael Luth</a></h6>
                                                    </div>
                                                    <div className="ms-auto text-end">
                                                        <p className="text-muted mb-0">10:35am</p>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 d-flex align-items-centermy-auto">
                                                    <div>
                                                        <span className="text-muted fw-normal fs-12">New Meetup Started......</span>
                                                    </div>
                                                    <div className="ms-auto text-end">
                                                        <a aria-label="anchor" href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close2"><i className="ti ti-x fs-16" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="dropdown-item">
                                        <div className="d-flex align-items-start">
                                            <div className="pe-2">
                                                <img src="../assets/images/faces/12.jpg" alt="img" className="avatar avatar-md avatar-rounded" />
                                            </div>
                                            <div className="w-100">
                                                <div className="flex-grow-1 d-flex align-items-centermy-auto">
                                                    <div>
                                                        <h6 className="mb-0 fw-semibold fs-14"><a href="chat.html">Brizid Dawson</a></h6>
                                                    </div>
                                                    <div className="ms-auto text-end">
                                                        <p className="text-muted mb-0">02:17am</p>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 d-flex align-items-centermy-auto">
                                                    <div>
                                                        <span className="text-muted fw-normal fs-12">Brizid is in the Warehouse...</span>
                                                    </div>
                                                    <div className="ms-auto text-end">
                                                        <a aria-label="anchor" href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close2"><i className="ti ti-x fs-16" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="dropdown-item">
                                        <div className="d-flex align-items-start">
                                            <div className="pe-2">
                                                <img src="../assets/images/faces/4.jpg" alt="img" className="avatar avatar-md avatar-rounded" />
                                            </div>
                                            <div className="w-100">
                                                <div className="flex-grow-1 d-flex align-items-centermy-auto">
                                                    <div>
                                                        <h6 className="mb-0 fw-semibold fs-14"><a href="chat.html">Shannon Shaw</a></h6>
                                                    </div>
                                                    <div className="ms-auto text-end">
                                                        <p className="text-muted mb-0">7:55pm</p>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 d-flex align-items-centermy-auto">
                                                    <div>
                                                        <span className="text-muted fw-normal fs-12">New Product Realease......</span>
                                                    </div>
                                                    <div className="ms-auto text-end">
                                                        <a aria-label="anchor" href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close2"><i className="ti ti-x fs-16" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="dropdown-item">
                                        <div className="d-flex align-items-start">
                                            <div className="pe-2">
                                                <img src="../assets/images/faces/3.jpg" alt="img" className="avatar avatar-md avatar-rounded" />
                                            </div>
                                            <div className="w-100">
                                                <div className="flex-grow-1 d-flex align-items-centermy-auto">
                                                    <div>
                                                        <h6 className="mb-0 fw-semibold fs-14"><a href="chat.html">Cherry Blossom</a></h6>
                                                    </div>
                                                    <div className="ms-auto text-end">
                                                        <p className="text-muted mb-0">7:55pm</p>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 d-flex align-items-centermy-auto">
                                                    <div>
                                                        <span className="text-muted fw-normal fs-12">You have appointment on......</span>
                                                    </div>
                                                    <div className="ms-auto text-end">
                                                        <a aria-label="anchor" href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close2"><i className="ti ti-x fs-16" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="p-3 empty-header-item2 border-top text-center">
                                    <a href="chat.html" className>View All Messages</a>
                                </div>
                                <div className="p-5 empty-item2 d-none">
                                    <div className="text-center">
                                        <span className="avatar avatar-xl avatar-rounded bg-danger-transparent">
                                            <i className="ri-message-2-line fs-2" />
                                        </span>
                                        <h6 className="fw-semibold mt-3">No New Messages</h6>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="header-element">
                            <a aria-label="anchor" href="javascript:void(0);" className="header-link dropdown-toggle" data-bs-toggle="offcanvas" data-bs-target="#sidebar-right">
                                <i className="fe fe-align-right header-link-icon" />
                            </a>
                        </div> */}

                        {isLoggedin &&
                            <Restricted allowedTo={[
                                REACT_APP_ROLES_SUPERADMIN,
                                REACT_APP_ROLES_ADMIN
                            ]}>
                                <div className="header-element notifications-dropdown">
                                    <a aria-label="anchor" href="javascript:void(0);" className="header-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" id="messageDropdown" aria-expanded="false">
                                        <i className="fe fe-bell header-link-icon" />
                                        <span className="w-9 h-9 p-0 bg-success rounded-pill header-icon-badge pulse pulse-success" id="notification-icon-badge" />
                                    </a>
                                    <div className="main-header-dropdown dropdown-menu dropdown-menu-end" data-popper-placement="none">
                                        <div className="p-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <p className="mb-0 fs-17 fw-semibold">Notifications</p>
                                                <span className="badge bg-secondary-transparent" id="notifiation-data">5 Unread</span>
                                            </div>
                                        </div>
                                        <div className="dropdown-divider" />
                                        <ul className="list-unstyled mb-0" id="header-notification-scroll">
                                            <li className="dropdown-item">
                                                <div className="d-flex align-items-start">
                                                    <div className="pe-2">
                                                        <span className="avatar avatar-md bg-primary avatar-rounded"><i className="fe fe-mail fs-18" /></span>
                                                    </div>
                                                    <div className="flex-grow-1 d-flex align-items-center my-auto">
                                                        <div>
                                                            <p className="mb-0 fw-semibold"><a href="notifications.html">New Application received</a></p>
                                                            <span className="text-muted fw-normal fs-12 header-notification-text">3 days ago</span>
                                                        </div>
                                                        <div className="ms-auto my-auto">
                                                            <a aria-label="anchor" href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close1"><i className="ti ti-x fs-16" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div className="d-flex align-items-start">
                                                    <div className="pe-2">
                                                        <span className="avatar avatar-md bg-secondary avatar-rounded"><i className="fe fe-check-circle fs-18" /></span>
                                                    </div>
                                                    <div className="flex-grow-1 d-flex align-items-center my-auto">
                                                        <div>
                                                            <p className="mb-0 fw-semibold"><a href="notifications.html">Project has been approved</a></p>
                                                            <span className="text-muted fw-normal fs-12 header-notification-text">2 hours ago</span>
                                                        </div>
                                                        <div className="ms-auto my-auto">
                                                            <a aria-label="anchor" href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close1"><i className="ti ti-x fs-16" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div className="d-flex align-items-start">
                                                    <div className="pe-2">
                                                        <span className="avatar avatar-md bg-success avatar-rounded"><i className="fe fe-shopping-cart fs-18" /></span>
                                                    </div>
                                                    <div className="flex-grow-1 d-flex align-items-center my-auto">
                                                        <div>
                                                            <p className="mb-0 fw-semibold"><a href="notifications.html">Your Product Delivered</a></p>
                                                            <span className="text-muted fw-normal fs-12 header-notification-text">30 min ago</span>
                                                        </div>
                                                        <div className="ms-auto my-auto">
                                                            <a aria-label="anchor" href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close1"><i className="ti ti-x fs-16" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <div className="d-flex align-items-start">
                                                    <div className="pe-2">
                                                        <span className="avatar avatar-md bg-pink avatar-rounded"><i className="fe fe-shopping-cart fs-18" /></span>
                                                    </div>
                                                    <div className="flex-grow-1 d-flex align-items-center my-auto">
                                                        <div>
                                                            <p className="mb-0 fw-semibold"><a href="notifications.html">Friend Requests</a></p>
                                                            <span className="text-muted fw-normal fs-12 header-notification-text">10 min ago</span>
                                                        </div>
                                                        <div className="ms-auto my-auto">
                                                            <a aria-label="anchor" href="javascript:void(0);" className="min-w-fit-content text-muted me-1 dropdown-item-close1"><i className="ti ti-x fs-16" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="p-3 empty-header-item1 border-top text-center">
                                            <a href="notifications.html" className>View All Notifications</a>
                                        </div>
                                        <div className="p-5 empty-item1 d-none">
                                            <div className="text-center">
                                                <span className="avatar avatar-xl avatar-rounded bg-secondary-transparent">
                                                    <i className="ri-notification-off-line fs-2" />
                                                </span>
                                                <h6 className="fw-semibold mt-3">No New Notifications</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="header-element cursor-pointer">
                                    <div className="header-link dropdown-toggle" data-bs-auto-close="outside" data-bs-toggle="dropdown">
                                        <span className="avatar avatar-md lh-1" >
                                            <i className="fe fe-sliders header-link-icon"></i>
                                        </span>
                                    </div>

                                    <ul className="main-header-dropdown dropdown-menu pt-0 header-profile-dropdown" data-popper-placement="none">
                                        <li className="dropdown-item">
                                            <DrNavigateLink className="d-flex w-100" navigatePath="#">
                                                <i className="fe fe-thumbs-up fs-18 me-2 text-primary" />
                                                {t('feedback')}
                                            </DrNavigateLink>
                                        </li>
                                        {/* <li>
                                                <DrNavigateLink className="dropdown-item text-wrap" navigatePath="/store-filters">
                                                    {t('filters')}
                                                </DrNavigateLink>
                                            </li> */}
                                        <li className="dropdown-item">
                                            <DrNavigateLink className="d-flex w-100" navigatePath="/mystore">
                                                <i className="fe fe-settings fs-18 me-2 text-primary" />
                                                {t('mystore')}
                                            </DrNavigateLink>
                                        </li>
                                        {/* <li>
                                                <DrNavigateLink className="dropdown-item text-wrap" navigatePath="/store-pricing">
                                                    {t('pricing')}
                                                </DrNavigateLink>
                                            </li> */}
                                        <li className="dropdown-item">
                                            <div className="d-flex w-100 no-select"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    dispatch(clearSmartPricerState());
                                                    navigate(getRouteByKey("SMARTPRICER"));
                                                }}>
                                                <i className="fe fe-trending-up fs-18 me-2 text-primary" />
                                                {t('smartpricer')}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Restricted>
                        }

                        <div className="header-element main-profile-user cursor-pointer">
                            <div className="header-link dropdown-toggle" id="mainHeaderProfile" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                <div className="d-flex align-items-center">
                                    <div className="me-xxl-2 me-0">
                                        <img src={customerInfo?.profilePicture || avatar} alt="no image" width={32} height={32} className="rounded-circle border" />
                                    </div>
                                    <div className="d-xxl-block d-none my-auto">
                                        <h6 className="fw-semibold mb-0 lh-1 fs-14">{consistantCapitalizeFirstLetter(customerInfo?.firstName)} {consistantCapitalizeFirstLetter(customerInfo?.lastName)}</h6>
                                        <span className="op-7 fw-normal d-block fs-11 text-muted">{t("welcomeText")}</span>
                                    </div>
                                </div>
                            </div>

                            <ul className="main-header-dropdown dropdown-menu pt-0 header-profile-dropdown dropdown-menu-end" aria-labelledby="mainHeaderProfile">
                                <li className="drop-heading d-xxl-none d-block">
                                    <div className="text-center">
                                        <h5 className="text-dark mb-0 fs-14 fw-semibold">{consistantCapitalizeFirstLetter(customerInfo?.firstName)} {consistantCapitalizeFirstLetter(customerInfo?.lastName)}</h5>
                                        <small className="text-muted">{t("welcomeText")}</small>
                                    </div>
                                </li>
                                {isLoggedin &&
                                    <>
                                        <Restricted allowedTo={[
                                            REACT_APP_ROLES_SUPERADMIN,
                                            REACT_APP_ROLES_ADMIN,
                                            REACT_APP_ROLES_ORDERS,
                                            REACT_APP_ROLES_STORE,
                                            REACT_APP_ROLES_LOGISTICS,
                                            REACT_APP_ROLES_INVENTORY,
                                            REACT_APP_ROLES_REPORT,
                                        ]}>
                                            <li className="dropdown-item">
                                                <DrNavigateLink className="d-flex w-100" navigatePath="/account">
                                                    <i className="fe fe-user fs-18 me-2 text-primary" />
                                                    {t('myaccount')}
                                                </DrNavigateLink>
                                            </li>
                                        </Restricted>
                                        <Restricted allowedTo={[
                                            REACT_APP_ROLES_SUPERADMIN,
                                            REACT_APP_ROLES_ADMIN
                                        ]}>
                                            <li className="dropdown-item">
                                                <DrNavigateLink className="d-flex w-100" navigatePath="/mywallet">
                                                    <i className="fe fe-credit-card fs-18 me-2 text-primary" />
                                                    {t('wallet')}
                                                </DrNavigateLink>
                                            </li>
                                            <li className="dropdown-item">
                                                <DrNavigateLink className="d-flex w-100" navigatePath="/messages">
                                                    <i className="fe fe-mail fs-18 me-2 text-primary" />
                                                    {t('messages')}
                                                </DrNavigateLink>
                                            </li>
                                            <li className="dropdown-item">
                                                <div className="d-flex w-100 cursor-pointer"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(withActionPromise(clearCreatePackagesState()));
                                                        navigate(getRouteByKey("MAIN_PACKAGES"));
                                                    }}>
                                                    <i className="fe fe-pocket fs-18 me-2 text-primary" />
                                                    {t('subscription')}
                                                </div>
                                            </li>
                                            <li className="dropdown-item">
                                                <DrNavigateLink className="d-flex w-100" navigatePath="/sub-sellers">
                                                    <i className="fe fe-settings fs-18 me-2 text-primary" />
                                                    {t('usersManagement')}
                                                </DrNavigateLink>
                                            </li>
                                            <li className="dropdown-item">
                                                <DrNavigateLink className="d-flex w-100" navigatePath="/forwarding-emails">
                                                    <i className="fe fe-fast-forward fs-18 me-2 text-primary" />
                                                    {t('forwardingEmails')}
                                                </DrNavigateLink>
                                            </li>
                                            <li className="dropdown-item">
                                                <DrNavigateLink className="d-flex w-100" navigatePath="/feedback-config">
                                                    <i className="fe fe-edit-3 fs-18 me-2 text-primary" />
                                                    {t('feedbackConfig')}
                                                </DrNavigateLink>
                                            </li>

                                        </Restricted>

                                        <Restricted allowedTo={[
                                            REACT_APP_ROLES_SUPERADMIN,
                                            REACT_APP_ROLES_ADMIN,
                                            REACT_APP_ROLES_ORDERS,
                                            REACT_APP_ROLES_STORE,
                                            REACT_APP_ROLES_LOGISTICS,
                                            REACT_APP_ROLES_INVENTORY,
                                            REACT_APP_ROLES_REPORT
                                        ]}>
                                            <li className="dropdown-item">
                                                <a className="d-flex w-100" href="#">
                                                    <i className="fe fe-headphones fs-18 me-2 text-primary" />
                                                    {t('support')}
                                                </a>
                                            </li>
                                            <li className="dropdown-item">
                                                <a className="d-flex w-100" href="#">
                                                    <i className="fe fe-lock fs-18 me-2 text-primary" />
                                                    {t('lockscreen')}
                                                </a>
                                            </li>
                                        </Restricted>
                                        <li className="dropdown-item">
                                            <div className="d-flex w-100 cursor-pointer text-danger"
                                                onClick={logOut}>
                                                <i className="fe fe-log-out fs-18 me-2" />
                                                {t('signout')}
                                            </div>
                                        </li>

                                    </>
                                }

                                {!isLoggedin &&
                                    <li className="dropdown-item">
                                        {/* <div className="d-flex w-100 cursor-pointer cursor-pointer"
                                            onClick={() =>
                                                dispatch(setActiveModal({ name: modals.login, isGlobal: true, title: i18next.t('drLogin:title') }))

                                            }

                                        >
                                            <i className="fe fe-log-in fs-18 me-2" />
                                            {t('signin')}
                                        </div> */}
                                        <DrNavigateLink className="d-flex w-100" navigatePath="/login">
                                            <i className="fe fe-log-in fs-18 me-2" />
                                            {t('signin')}
                                        </DrNavigateLink>
                                    </li>

                                }


                            </ul>
                        </div>
                        {isLoggedin && false &&
                            <div className="header-element header-offcanvas-config">
                                <a aria-label="anchor" href="#" className="header-link switcher-icon" data-bs-toggle="offcanvas" data-bs-target="#switcher-canvas">
                                    <i className="bx bx-cog header-link-icon" />
                                </a>
                            </div>
                        }

                    </div>

                </div>
            </header >
        </>

    );
}

export default DrHeader;

