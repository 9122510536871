import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DrBreadCrump } from '../../components/component-index';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { modals, siteExtensions } from '../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { deleteProductsByAsins, getProductsDetailsByAsins, saveProductAsinBlock, saveProductBrandBlock } from '../../state-management/actions/inventory.actions';
import noImage from '../../assets/img/pngs/no-image.png'
import { getProductKeepaDetails } from '../../state-management/actions/keepa.actions';
import { flaggedCountryOptions, inventoryPageOptions } from '../../components/hoc/Constants/dr-constants.component';
import classNames from 'classnames';
import { setProductDetailPageSelections } from '../../state-management/actions/settings.actions';
import { toastr } from 'react-redux-toastr';

function ProductDetailPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { asin } = useParams();
    const { t } = useTranslation('productDetailPage');
    const selectedProducts = useSelector(state => state.inventory.selectedProducts);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const selectedProductKeepaDetails = useSelector(state => state.keepa.selectedProductKeepaDetails);
    const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.productDetailPageSelections);

    useEffect(() => {
        let panelProductDetails = document.getElementsByClassName("panelProductDetails");
        if (panelProductDetails && panelProductDetails?.length > 0) {
            panelProductDetails[0].addEventListener("show.bs.collapse", function (e) {
                // let shownItems = Object.assign({}, pageSelections?.detailedSearchShownAccordions);
                var shownItems = pageSelections?.productDetailShownAccordions;

                if (e?.target?.id === "panelDBSourceOptions") {
                    shownItems.panelDBSource = true;
                }
                if (e?.target?.id === "panelKeepaSourceOptions") {
                    shownItems.panelKeepaSource = true;
                }

                dispatch(withActionPromise(setProductDetailPageSelections({ productDetailShownAccordions: shownItems })));
            });
            panelProductDetails[0].addEventListener("hide.bs.collapse", function (e) {
                // let shownItems = Object.assign({}, pageSelections?.detailedSearchShownAccordions);
                var shownItems = pageSelections?.productDetailShownAccordions;

                if (e?.target?.id === "panelDBSourceOptions") {
                    shownItems.panelDBSource = false;
                }
                if (e?.target?.id === "panelKeepaSourceOptions") {
                    shownItems.panelKeepaSource = false;
                }

                dispatch(withActionPromise(setProductDetailPageSelections({ productDetailShownAccordions: shownItems })));
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (asin && selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getProductsDetailsByAsins({
                asinList: [asin],
                customerMainSellerId: selectedStore?.customerMainSellerId
            })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asin]);

    useEffect(() => {
        if (selectedProducts && selectedProducts.length > 0) {
            dispatch(withActionPromise(getProductKeepaDetails({ productMarketId: selectedProducts[0]?.productMarketId })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProducts]);

    var panelDBSourceClass = classNames({
        'panelSalesMarket': true,
        'accordion-collapse collapse': true,
        'show': pageSelections?.productDetailShownAccordions?.panelDBSource === null || pageSelections?.productDetailShownAccordions?.panelDBSource === true,
    });

    var panelDBSourceButtonClass = classNames({
        'bg-info bg-opacity-75': true,
        'text-white': true,
        'accordion-button': true,
        'collapsed': pageSelections?.productDetailShownAccordions?.panelDBSource === false,
    });

    var panelKeepaSourceClass = classNames({
        'panelSourceMarket': true,
        'accordion-collapse collapse': true,
        'show': pageSelections?.productDetailShownAccordions?.panelKeepaSource === null || pageSelections?.productDetailShownAccordions?.panelKeepaSource === true,
    });

    var panelKeepaSourceButtonClass = classNames({
        'bg-success bg-opacity-75': true,
        'text-white': true,
        'accordion-button': true,
        'collapsed': pageSelections?.productDetailShownAccordions?.panelKeepaSource === false
    });


    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
                { navigationText: i18next.t('constants:breadCrumps.productDetail'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                    <div className="row">
                        <div className="col-12">
                            {selectedProducts && selectedProducts.length > 0 &&
                                <div className="card custom-card mb-2">
                                    <div className="card-body">
                                        <div className="row justify-content-sm-center justify-content-md-center justify-content-center">
                                            <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 px-0">
                                                <div className="w-100 h-100 bg-gray-300 p-2">
                                                    <img alt="" className="img-thumbnail h-max cursor-pointer" src={selectedProducts[0]?.productPicture || noImage}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (selectedProducts[0]?.productPicture) {
                                                                dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: [selectedProducts[0]?.productPicture] }))
                                                            }
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                                                <div className="row mb-1">
                                                    <div className="col-12">
                                                        <h3 class="card-title fw-bold mb-3">{selectedProducts[0]?.productName}</h3>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4 col-md-4 col-sm-4">
                                                        <p className="fs-13 fw-semibold mb-1">ASIN:</p>
                                                    </div>
                                                    <div className="col-8 col-md-8 col-sm-8">
                                                        <label>
                                                            <span className="text-white fs-13 fw-bold badge bg-info cursor-pointer">
                                                                {selectedProducts[0]?.asin}
                                                            </span>
                                                            <span onClick={(e) => {
                                                                e.preventDefault();
                                                                navigator.clipboard.writeText(selectedProducts[0]?.asin);
                                                                toastr.info("Info", selectedProducts[0]?.asin + " is copied to clipboard", { timeOut: 1000 })
                                                            }}>
                                                                <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                                                            </span>

                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4 col-md-4 col-sm-4">
                                                        <p className="fs-13 fw-semibold mb-1">SKU:</p>
                                                    </div>
                                                    <div className="col-8 col-md-8 col-sm-8">
                                                        <label>
                                                            <span className="fs-13 text-muted fw-semibold mb-1">{selectedProducts[0]?.sku}</span>
                                                            <span onClick={(e) => {
                                                                e.preventDefault();
                                                                navigator.clipboard.writeText(selectedProducts[0]?.sku);
                                                                toastr.info("Info", selectedProducts[0]?.sku + " is copied to clipboard", { timeOut: 1000 })
                                                            }}>
                                                                <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4 col-md-4 col-sm-4">
                                                        <p className="fs-13 fw-semibold mb-1">Product Status:</p>
                                                    </div>
                                                    <div className="col-8 col-md-8 col-sm-8">
                                                        <span className="badge bg-teal">{inventoryPageOptions.find(x => x.value === selectedProducts[0]?.status)?.label}</span>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-4 col-md-4 col-sm-4">
                                                        <p className="fs-13 fw-semibold mb-1">URL:</p>
                                                    </div>
                                                    <div className="col-8 col-md-8 col-sm-8">
                                                        <label>
                                                            <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                                                href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === selectedStore.countryCode)?.extension + "/" + selectedProducts[0]?.modelNumber + "/dp/" + selectedProducts[0]?.asin}>
                                                                Amazonda Görüntüle
                                                            </a>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <div className="row mb-1">
                                                            <div className="col-12">
                                                                <h3 class="card-title fw-bold mb-3">{t("productProperties")}</h3>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                                <p className="fs-13 fw-semibold mb-1">{t("width")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                                <span >{selectedProducts[0]?.itemWidthValue && Number(selectedProducts[0]?.itemWidthValue).toFixed(2)} {selectedProducts[0]?.itemWidthUnit}</span>
                                                            </div>
                                                            <div className="col-auto ps-0 pe-0">

                                                            </div>
                                                            <div className="col-4 col-xl-auto col-md-auto col-sm-auto">
                                                                <p className="fs-13 fw-semibold mb-1">{t("packageWidth")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                                <span >{selectedProducts[0]?.packageWidthValue && Number(selectedProducts[0]?.packageWidthValue).toFixed(2)} {selectedProducts[0]?.packageWidthUnit}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                                <p className="fs-13 fw-semibold mb-1">{t("length")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                                <span >{selectedProducts[0]?.itemLengthValue && Number(selectedProducts[0]?.itemLengthValue).toFixed(2)} {selectedProducts[0]?.itemLengthUnit}</span>
                                                            </div>
                                                            <div className="col-auto ps-0 pe-0">

                                                            </div>
                                                            <div className="col-4 col-xl-auto col-md-auto col-sm-auto">
                                                                <p className="fs-13 fw-semibold mb-1">{t("packageLength")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                                <span >{selectedProducts[0]?.packageLengthValue && Number(selectedProducts[0]?.packageLengthValue).toFixed(2)} {selectedProducts[0]?.packageLengthUnit}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                                <p className="fs-13 fw-semibold mb-1">{t("height")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                                <span >{selectedProducts[0]?.itemHeightValue && Number(selectedProducts[0]?.itemHeightValue).toFixed(2)} {selectedProducts[0]?.itemHeightUnit}</span>
                                                            </div>
                                                            <div className="col-auto ps-0 pe-0">

                                                            </div>
                                                            <div className="col-4 col-xl-auto col-md-auto col-sm-auto">
                                                                <p className="fs-13 fw-semibold mb-1">{t("packageHeight")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                                <span >{selectedProducts[0]?.packageHeightValue && Number(selectedProducts[0]?.packageHeightValue).toFixed(2)} {selectedProducts[0]?.packageHeightUnit}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                                <p className="fs-13 fw-semibold mb-1">{t("weight")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                                <span >{selectedProducts[0]?.itemWeightValue && Number(selectedProducts[0]?.itemWeightValue).toFixed(2)} {selectedProducts[0]?.itemWeightUnit}</span>
                                                            </div>
                                                            <div className="col-auto ps-0 pe-0">

                                                            </div>
                                                            <div className="col-4 col-xl-auto col-md-auto col-sm-auto">
                                                                <p className="fs-13 fw-semibold mb-1">{t("packageWeight")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                                {selectedProducts[0]?.packageWeightValue && <span >{Number(selectedProducts[0]?.packageWeightValue).toFixed(2)} {selectedProducts[0]?.packageWeightUnit}</span>}
                                                                {!selectedProducts[0]?.packageWeightValue && <span>n/a</span>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                                <p className="fs-13 fw-semibold mb-1">{t("partNumber")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                                <span >{selectedProducts[0]?.partNumber}</span>
                                                            </div>
                                                            <div className="col-auto ps-0 pe-0">

                                                            </div>
                                                            <div className="col-4 col-xl-auto col-md-auto col-sm-auto">
                                                                <p className="fs-13 fw-semibold mb-1">{t("packageQuantity")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-3 col-md-3 col-sm-3 ps-0">
                                                                <span >{selectedProducts[0]?.itemPackageQuantity}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-4 col-xl-2 col-md-2 col-sm-2">
                                                                <p className="fs-13 fw-semibold mb-1">{t("variation")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-10 col-md-10 col-sm-10 ps-0">
                                                                <span >{selectedProducts[0]?.size} {selectedProducts[0]?.color} {selectedProducts[0]?.style}</span>
                                                            </div>
                                                        </div>
                                                        {selectedProducts[0]?.fabricType && selectedProducts[0]?.fabricType.length > 0 &&
                                                            <div className="row">
                                                                <div className="col-4 col-xl-3 col-md-3 col-sm-3">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("fabricType")}</p>
                                                                </div>
                                                                <div className="col-8 col-xl-9 col-md-9 col-sm-9 ps-0">
                                                                    <span >{selectedProducts[0]?.fabricType}</span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {selectedProducts[0]?.isLiquidContent && <div className="row">
                                                            <div className="col-4 col-xl-3 col-md-3 col-sm-3">
                                                                <p className="fs-13 fw-semibold mb-1">{t("isLiquidContent")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-9 col-md-9 col-sm-9 ps-0">
                                                                <span >{selectedProducts[0]?.isLiquidContent && t("yes")}</span>
                                                            </div>
                                                        </div>}
                                                        {selectedProducts[0]?.isBatteryRequired && <div className="row">
                                                            <div className="col-4 col-xl-3 col-md-3 col-sm-3">
                                                                <p className="fs-13 fw-semibold mb-1">{t("isBatteryRequired")}</p>
                                                            </div>
                                                            <div className="col-8 col-xl-9 col-md-9 col-sm-9 ps-0">
                                                                <span >{selectedProducts[0]?.isBatteryRequired && t("yes")}</span>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="panelProductDetails accordion">
                                <div className="accordion-item mb-1 shadow">
                                    <h2 className="accordion-header ">
                                        <button className={panelDBSourceButtonClass}
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#panelDBSourceOptions" aria-expanded="true"
                                            aria-controls="panelDBSourceOptions">
                                            Product Detail
                                        </button>
                                    </h2>
                                    <div id="panelDBSourceOptions" className={panelDBSourceClass}>
                                        <div className="accordion-body bg-info bg-opacity-10">
                                            {/* <div className="card custom-card bg-info bg-opacity-10">
                                                <div className="card-body p-2"> */}
                                            <div className="row">
                                                <div className="col-6 ">
                                                    <div className="card custom-card bg-light  mb-2 shadow">
                                                        <div className="card-body p-3">
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">Kaynak pazarda ürünün satıcısı:</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >alan yok</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">Satıcı tipi:</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >alan yok</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">Kaynak pazarda sales rank:</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >alan yok</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">Marka:</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.brandName}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">USPTO tescil durumu:</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >alan yok</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">CIPO tescil durumu:</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >alan yok</span>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("isPrimeExclusive")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.isPrimeExclusive ? t("yes") : t("no")}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("isBestSelling")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >alan yok</span>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("isFBASellerExist")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.isFBASellerExists ? t("yes") : t("no")}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("isChineseSellerExist")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.isChineseSellerExists ? t("yes") : t("no")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card custom-card bg-light  mb-2 shadow">
                                                        <div className="card-body p-3">
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("salesMarketMainCategory")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.categoryName}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("salesMaketSubCategory")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >alan yok</span>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("salesMarketMainCategorySalesRank")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.displayGroupRank}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("salesMarketSubCategorySalesRank")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.classificationRank}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("sourceMarketMainCategorySalesRank")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >alan yok</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("sourceMarketSubCategorySalesRank")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >alan yok</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 ">
                                                    <div className="card custom-card bg-success bg-opacity-10 mb-2 shadow">
                                                        <div className="card-body p-3">
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("minPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >alan yok</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("maxPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >alan yok</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("sellPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span className="fs-14 fw-bold">{selectedStoreCurrencyType.currencyCode} {Number(selectedProducts[0]?.sellingPrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("stock")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.stock}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card custom-card bg-danger bg-opacity-10 mb-2 shadow">
                                                        <div className="card-body p-3">
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("listingPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.listPriceCurrencyCode} {Number(selectedProducts[0]?.listPrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("estimatedShippingPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.shippingPriceCurrencyCode} {Number(selectedProducts[0]?.shippingPrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("warehousePrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.shippingPriceCurrencyCode} {Number(selectedProducts[0]?.warehousePrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("amazonCommission")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.shippingPriceCurrencyCode} {Number(selectedProducts[0]?.amazonFeePrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="card custom-card bg-info bg-opacity-10 mb-2 shadow">
                                                        <div className="card-body p-3">
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("buyboxSeller")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.buyboxOwnerSeller}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("buyboxPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.buyboxPriceCurrencyCode} {Number(selectedProducts[0]?.buyboxPrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("lowestSeller")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.lowestPriceSeller}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("lowestPrice")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.lowestPriceCurrencyCode} {Number(selectedProducts[0]?.lowestPrice).toFixed(2)}</span>
                                                                </div>
                                                            </div>
                                                            <hr></hr>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("primeSellerCount")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.primeSellerCount}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-1">
                                                                <div className="col-8 col-md-8 col-sm-8">
                                                                    <p className="fs-13 fw-semibold mb-1">{t("totalSellerCount")}</p>
                                                                </div>
                                                                <div className="col-4 col-md-4 col-sm-4">
                                                                    <span >{selectedProducts[0]?.totalSellerCount}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {selectedProductKeepaDetails &&
                                    <div className="accordion-item mb-1 shadow">
                                        <h2 className="accordion-header">
                                            <button className={panelKeepaSourceButtonClass}
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target="#panelKeepaSourceOptions" aria-expanded="true"
                                                aria-controls="panelKeepaSourceOptions">
                                                Keepa Product Detail
                                            </button>
                                        </h2>
                                        <div id="panelKeepaSourceOptions" className={panelKeepaSourceClass}>
                                            <div className="accordion-body bg-success bg-opacity-10">
                                                <div id="keepa-product-detail-tab" className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank30DayAvg")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank30DayAvg} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank90DayAvg")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank90DayAvg} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank30DayDrop")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank30DayDrop} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank90DayDrop")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank90DayDrop} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank30DropLastDay")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank30DropLastDay} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.salesRank90DropLastDay")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span > {selectedProductKeepaDetails.salesRank90DropLastDay} </span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.amazonCurrentPrice")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0]?.amazonCurrentPrice).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.referralFeePercent")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.referralFeePercent}%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.referralFeePercent")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedStoreCurrencyType.currencyCode}{Number(selectedProducts[0]?.referralFeeBasedOnCurrentBuyBoxPrice).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.fBAPickAndPackFee")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>USD {Number(selectedProductKeepaDetails.fBAPickAndPackFee).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.newOfferCount")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.newOfferCount}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.newOfferCountCurrent")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.newOfferCountCurrent}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.newOfferCount30DayAvg")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.newOfferCount30DayAvg}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.newFBMRetrievedLiveOfferCount")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.newFBMRetrievedLiveOfferCount}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.newFBARetrievedLiveOfferCount")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.newFBARetrievedLiveOfferCount}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBoxCurrent")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>USD {Number(selectedProductKeepaDetails.buyBoxCurrent).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBox30DayAvg")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>USD {Number(selectedProductKeepaDetails.buyBox30DayAvg).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBox30DayDropPercent")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.buyBox30DayDropPercent}%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBox90DayDropPercent")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.buyBox90DayDropPercent}%</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBoxSeller")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.buyBoxSeller}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.buyBoxUnqualified")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.buyBoxUnqualified ? <i className="fa fa-close text-danger"></i> : <i className="fa fa-check text-success"></i>}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.boughtInPastMonth")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.boughtInPastMonth}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 text-end">
                                                                <p className="fs-13 fw-semibold mb-1">{t("keepa.freqBoughtTogether")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <span>{selectedProductKeepaDetails.freqBoughtTogether}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>


                    </div>
                    
                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 px-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card custom-card mb-2">
                                <div className="card-body">
                                    {selectedProducts?.length > 0 && <>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0">
                                                {flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.icon}
                                            </div>
                                            <div className="col">
                                                <label>
                                                    <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                                        href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === selectedStore.countryCode)?.extension + "/" + selectedProducts[0]?.modelNumber + "/dp/" + selectedProducts[0]?.asin}>
                                                        {flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label} marketinde görüntüle
                                                    </a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0">
                                                {flaggedCountryOptions?.find(x => x.value === selectedProducts[0]?.sourceMarketCountryCode)?.icon}
                                            </div>
                                            <div className="col">
                                                <label>
                                                    <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                                        href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === selectedProducts[0]?.sourceMarketCountryCode)?.extension + "/" + selectedProducts[0]?.modelNumber + "/dp/" + selectedProducts[0]?.asin}>
                                                        {flaggedCountryOptions?.find(x => x.value === selectedProducts[0]?.sourceMarketCountryCode)?.label} marketinde görüntüle
                                                    </a>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-1 px-0">
                                                <i className="bx bxs-show fs-20 me-1"></i>
                                            </div>
                                            <div className="col">
                                                <label>
                                                    <a className="text-primary cursor-pointer" target="_blank" rel="noreferrer"
                                                        href={"https://sellercentral.amazon." + siteExtensions.find(x => x.countryCode === selectedStore?.countryCode)?.extension + "/abis/listing/edit/offer?asin=" + selectedProducts[0]?.asin + "&sku=" + selectedProducts[0]?.sku + "&marketplaceID=" + selectedStore?.marketSerialNumber}>
                                                        SellerCentral'da görüntüle
                                                    </a>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0">
                                                <i className="bx bx-rotate-left fs-20 me-1"></i>
                                            </div>
                                            <div className="col">
                                                <label>
                                                    <span className="text-primary cursor-pointer"
                                                        onClick={(e) => {
                                                            navigate('/update-list/' + selectedProducts[0]?.asin);
                                                        }}
                                                    >
                                                        Güncelleme geçmişini görüntüle
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0" >
                                                <i class='bx bx-block fs-20 me-1 text-danger' ></i>
                                            </div>
                                            <div className="col">
                                                <span className="cursor-pointer text-danger"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        var requestData = {
                                                            customerMainSellerId: selectedStore?.customerMainSellerId,
                                                            asinList: [
                                                                selectedProducts[0]?.asin
                                                            ]
                                                        }

                                                        dispatch(withActionPromise(saveProductAsinBlock(requestData)));
                                                    }}>
                                                    Ürün Asin'ini engelle
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-1 px-0" >
                                                <i class='bx bx-block fs-20 me-1 text-danger' ></i>
                                            </div>
                                            <div className="col">
                                                <span className="cursor-pointer text-danger"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        var requestData = {
                                                            customerMainSellerId: selectedStore?.customerMainSellerId,
                                                            productBrandNames: [selectedProducts[0]?.brandName],
                                                            isBlocked: true
                                                        }
                                                        dispatch(saveProductBrandBlock(requestData));

                                                    }}>
                                                    Engellenen markalara ekle
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-1 px-0" >
                                                <i class='bx bx-block fs-20 me-1 text-danger' ></i>
                                            </div>
                                            <div className="col">
                                                <span className="cursor-pointer text-danger"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        var requestData = {
                                                            deleteRequestData: {
                                                                asins: [
                                                                    selectedProducts[0]?.asin
                                                                ],
                                                                customerMainSellerId: selectedStore?.customerMainSellerId
                                                            }
                                                        }

                                                        dispatch(withActionPromise(deleteProductsByAsins(requestData)));

                                                    }}>
                                                    Ürünü mağazamdan kaldır
                                                </span>
                                            </div>
                                        </div>
                                    </>}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">

                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            Kargo ücreti biliniyor mu?
                                        </div>
                                        <div className="col-3">
                                            <span>{selectedProducts[0]?.shippingPrice > 0 ? "Evet" : "Hayır"}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            Gümrük ücreti biliniyor mu?
                                        </div>
                                        <div className="col-3">
                                            <span>{selectedProducts[0]?.customsDutyPrice > 0 ? "Evet" : "Hayır"}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            Stokta var mı?
                                        </div>
                                        <div className="col-3">
                                            <span>{selectedProducts[0]?.stock > 0 ? "Evet" : "Hayır"}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            İndrimli ürün mü?
                                        </div>
                                        <div className="col-3">
                                            <span>{selectedProducts[0]?.isDiscounted > 0 ? "Evet" : "Hayır"}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            Kuponlu ürün mü?
                                        </div>
                                        <div className="col-3">
                                            <span>{selectedProducts[0]?.couponValue?.length > 0 ? "Evet" : "Hayır"}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            Prime indirimli ürün mü?
                                        </div>
                                        <div className="col-3">
                                            {/* <span>{selectedProducts[0]?.primeValue?.length > 0 ? "Evet" : "Hayır"}</span> */}
                                            alan yok
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            Son envanter güncelleme tarihi:
                                        </div>
                                        <div className="col-3">
                                            {/* <span>{selectedProducts[0]?.primeValue?.length > 0 ? "Evet" : "Hayır"}</span> */}
                                            alan yok
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-9">
                                            Son ürün güncelleme tarihi:
                                        </div>
                                        <div className="col-3">
                                            {/* <span>{selectedProducts[0]?.primeValue?.length > 0 ? "Evet" : "Hayır"}</span> */}
                                            alan yok
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default ProductDetailPage